import React, { Suspense, Fragment, lazy } from "react";
import { Routes, Route, Navigate } from "react-router-dom";

import Loader from "./components/Loader/Loader";
import AdminLayout from "./layouts/AdminLayout";

export const renderRoutes = (routes = []) => (
  <Suspense fallback={<Loader />}>
    <Routes>
      {routes.map((route, i) => {
        const Guard = route.guard || Fragment;
        const Layout = route.layout || Fragment;
        const Element = route.element;

        return (
          <Route
            key={i}
            path={route.path}
            element={
              <Guard>
                <Layout>
                  {route.routes ? (
                    renderRoutes(route.routes)
                  ) : (
                    <Element props={true} />
                  )}
                </Layout>
              </Guard>
            }
          />
        );
      })}
    </Routes>
  </Suspense>
);

const routes = [
  {
    exact: "true",
    path: "/login",
    element: lazy(() => import("./views/auth/signin/SignIn1")),
  },
  {
    exact: "true",
    path: "/home",
    element: lazy(() => import("./views/website/home/HomePage")),
  },
  {
    exact: "true",
    path: "/auth/signin-1",
    element: lazy(() => import("./views/auth/signin/SignIn1")),
  },
  {
    exact: "true",
    path: "/auth/signup-1",
    element: lazy(() => import("./views/auth/signup/SignUp1")),
  },
  {
    exact: "true",
    path: "/auth/reset-password-1",
    element: lazy(() => import("./views/auth/reset-password/ResetPassword1")),
  },
  {
    path: "*",
    layout: AdminLayout,
    routes: [
      {
        exact: "true",
        path: "/app/home",
        element: lazy(() => import("./views/home_page/HomePage")),
      },
      {
        exact: "true",
        path: "/app/pdf",
        element: lazy(() => import("./components/Generic/GeneratePdf")),
      },
      {
        exact: "true",
        path: "/app/pdf/edit-template",
        element: lazy(() => import("./views/pdf/PdfEditTemplate")),
      },
      {
        exact: "true",
        path: "/app/invoices",
        element: lazy(() => import("./views/invoices/Invoices")),
      },
      {
        exact: "true",
        path: "/app/customers",
        element: lazy(() => import("./views/customers/Customers")),
      },
      {
        exact: "true",
        path: "/app/charts",
        element: lazy(() => import("./views/charts/nvd3-chart")),
      },
      {
        exact: "true",
        path: "/app/dashboard/default",
        element: lazy(() => import("./views/dashboard")),
      },
      {
        exact: "true",
        path: "/app/products",
        element: lazy(() => import("./views/products/Products")),
      },
      {
        exact: "true",
        path: "/basic/badges",
        element: lazy(() => import("./views/ui-elements/basic/BasicBadges")),
      },
      {
        exact: "true",
        path: "/basic/breadcrumb",
        element: lazy(() =>
          import("./views/ui-elements/basic/BasicBreadcrumb")
        ),
      },
      {
        exact: "true",
        path: "/basic/pagination",
        element: lazy(() =>
          import("./views/ui-elements/basic/BasicPagination")
        ),
      },
      {
        exact: "true",
        path: "/basic/collapse",
        element: lazy(() => import("./views/ui-elements/basic/BasicCollapse")),
      },
      {
        exact: "true",
        path: "/basic/tabs-pills",
        element: lazy(() => import("./views/ui-elements/basic/BasicTabsPills")),
      },
      {
        exact: "true",
        path: "/basic/typography",
        element: lazy(() =>
          import("./views/ui-elements/basic/BasicTypography")
        ),
      },
      {
        exact: "true",
        path: "/forms/form-basic",
        element: lazy(() => import("./views/forms/FormsElements")),
      },
      {
        exact: "true",
        path: "/tables/bootstrap",
        element: lazy(() => import("./views/tables/BootstrapTable")),
      },
      {
        exact: "true",
        path: "/maps/google-map",
        element: lazy(() => import("./views/maps/GoogleMaps")),
      },
      {
        exact: "true",
        path: "/sample-page",
        element: lazy(() => import("./views/extra/SamplePage")),
      },
      {
        path: "*",
        exact: "true",
        element: () => (
          <Navigate to={localStorage.getItem("user") ? "/app/home" : "/home"} />
        ),
      },
    ],
  },
];

export default routes;
